import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Layout from "../../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../../components/Navigation/Navigation";
import NonRegisteredContributeYou
    from "../../../../components/ContentRow/Step3/NonRegistered/NonRegisteredContribute/NonRegisteredContributeYou";
import NonRegisteredContributeSpouse
    from "../../../../components/ContentRow/Step3/NonRegistered/NonRegisteredContribute/NonRegisteredContributeSpouse";
import { thirdStepCompleted } from "../../../../store/actions/savingsAndInvestments";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const NonRegisteredContribute = ( props ) => {
    const titles = {
        layoutTitle: 'Non-Registered',
        sectionTitle: 'How much do you plan to contribute monthly?',
    };

    const title = "Monthly contribution in Non-Registered";

    const next = retirementCalcLinks.properties;

    useEffect(() => {
        props.thirdStepCompleted(false)
    }, [props])

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep3={(12/16)*100}
            active={'step3'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent=''
                sectionTitle={titles.sectionTitle}
                modal={false}
            >

                {
                    props.you.nonRegistered.exist &&
                    <InnerLayoutStepContent type={'you'}>
                        <NonRegisteredContributeYou/>
                    </InnerLayoutStepContent>
                }

                {
                    props.isSpouse && props.spouse.nonRegistered.exist &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <NonRegisteredContributeSpouse/>
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        you: state.savingsAndInvestments.you,
        spouse: state.savingsAndInvestments.spouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NonRegisteredContribute);